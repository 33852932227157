<template>
  <div>
    <div class="box">
      <div class="button-left">
        <!-- 右箭头 -->
        <div class="swiper-button-next"></div>
      </div>
      <div class="swiperaa">
        <div class="swiper mySwiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide" v-for="item in moduleDataSource" :key="item.id" @click="detail(item.img)">
              <div class="img"><img :src="item.img" alt="" /></div>
              <div class="title">{{ item.title }}</div>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <div class="button-right">
        <!-- 左箭头 -->
        <div class="swiper-button-prev"></div>
      </div>
    </div>
    <!-- 图片弹框 -->
    <el-dialog :visible="dialogVisible" @close="close" width="50%" style="overflow: hidden;">
      <div><img :src="img" alt="" style="width:100%"></div>
    </el-dialog>
  </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
export default {
  name: 'SanNong',
  props: {
    moduleDataSource: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      dialogVisible: false,
      img: null
    }
  },
  mounted () {
    // eslint-disable-next-line no-new
    new Swiper('.mySwiper', {
      slidesPerView: 3,
      spaceBetween: 30,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    })
  },
  methods: {
    detail (img) {
      this.img = img
      this.dialogVisible = true
    },
    close () {
      this.dialogVisible = false
    }
  }
}
</script>

<style scoped>
.box {
  width: 100%;
  height: 270px;
  margin: 0 auto;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  position: relative;
}
.button-left {
  width: 44px;
  height: 20px;
}
.button-right {
  width: 44px;
  height: 20px;
}
.swiperaa {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.swiper-slide {
  height: 300px;
  width: 100px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img {
  width: 100%;
  height: 218px;
}
.title {
  margin-top: 13px;
  text-align: center;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
}
/* 左右箭头 */

.swiper-button-next,
.swiper-button-prev {
  top: 39%;
  color: rgb(120, 120, 120);
}
/* 分页器 */
.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: -29px;
  --swiper-theme-color: rgb(65, 64, 64);
}
</style>
