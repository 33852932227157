<template>
	<div class="wrap-banner">
		<template v-if="bannerList.length==1">
			<el-row>
				<el-col :span="24" style="height: 400px;"><el-image style="width: 100%; height: 400px" :src="getImgSrc(bannerList[0])"></el-image></el-col>
			</el-row>
		</template>
		<template v-else>
			<el-carousel height="400px">
				<el-carousel-item v-if="item" v-for="(item,index) in bannerList" :key="index">
					<div class="wrap-img">
						<div class="wrap-info">
							<div style="width:800px;margin:0 auto;">
								<div class="title" v-if="title">{{ title }}</div>
								<div class="info" v-if="info"> {{ info }}</div>
							</div>
						</div>
						<el-image fit="cover" style="width: 100%; height: 400px" :src="getImgSrc(item)"></el-image>
					</div>
				</el-carousel-item>
			</el-carousel>
		</template>
	</div>
</template>

<script>
	import {
		getFileAccessHttpUrl
	} from '@/utils/util.js'
	export default {
		name: 'Banner',
		props: {
			moduleDataSource: {
				type: Array,
				default: () => []
			},
			moduleLinkConfig:{
				type:Object
			},
		},
		data(){
			return {
				bannerList:[],
				title:'',
				info:''
			}
		},
		methods: {
			getImgSrc(url) {
				return getFileAccessHttpUrl(url)
			}
		},
		mounted(){
			this.title = this.moduleDataSource[0].title
			this.info = this.moduleDataSource[0].info
			let imgArr = this.moduleDataSource[0].banner
			if(imgArr.indexOf(',')>-1){
				imgArr = imgArr.split(',')
				imgArr.forEach(item=>{
					this.bannerList.push(item)
				})
			}else{
				this.bannerList.push(imgArr)
			}
		}
	}
</script>

<style scoped>
	.wrap-banner {
		text-align: center;
		height: 400px;
		overflow: hidden;
	}
	.wrap-img{
		position: relative;
	}
	.wrap-info{
		position: absolute;
		top:0;
		left:0;
		bottom:0;
		right:0;
		z-index: 100;
		color: #fff;
		background: rgba(0, 0, 0, 0.4);
	}
	.title{
		font-size: 24px;
		text-align: left;
		margin-top:140px;
	}
	.info{
		text-align: left;
		margin-top:20px;
	}
</style>
