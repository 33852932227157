<template>
	<div class="top-position">
		<div class="container">
			<el-menu :default-active="currentRouterPath" class="el-menu-demo" mode="horizontal" text-color="#333"
				active-text-color="#3CA079">
				<el-menu-item v-for="(item,index) in list" v-if="item.hidden==1" :index="item.path">
					<!-- <router-link :to="{path:item.path}">{{item.meta.title}}</router-link> -->
					<a href="javascript:void(0);" @click="toLink(item.path)">{{item.meta.title}}</a>
				</el-menu-item>
			</el-menu>
		</div>
	</div>
</template>

<script>
	import store from '@/store'
	export default {
		name: 'SiteMenu',
		data() {
			return {
				list: [],
				currentRouterPath:''
			};
		},
		watch: {
			'$route': {
				//执行 val:当前最新值 old:上一刻的值
				handler: function(val, old) {
					let current = this.$route.path
					this.currentRouterPath = ''
					this.list = store.getters.permission_routes
					console.log(this.list)
					this.list.forEach(item=>{
						if(item.path== current && item.hidden == 1 ){
							this.currentRouterPath = current
						}
					})
					if(!this.currentRouterPath){
						let currentSplit = current.split('/')[1]
						this.list.forEach(item=>{
							if(item.path.indexOf(currentSplit)>-1 && item.hidden == 1){
								this.currentRouterPath = item.path
							}
						})
					}
					
				},
				// 立即处理 进入页面就触发
				immediate: true,
				// 深度监听 属性的变化
				deep: true
			},
		},
		methods:{
			toLink(item){
				if(item.indexOf('http')>-1){
					window.open(item)
				}else{
					this.$router.push(item)
				}
			}
		}
	};
	
	
</script>

<style scoped>
	.top-position{
		width:100%;
		background:#ffff;
	}
	.container {
		height: 52px;
		width: 1200px;
		margin: 0 auto;
	}

	/deep/ .el-input__inner {
		height: 36px;
		line-height: 36px;
		font-size: 14px;
		color: #999;
	}

	.el-menu--horizontal {
		border: none;
	}

	.el-menu--horizontal>.el-menu-item {
		height: 52px;
		line-height: 52px;
		font-size: 16px;
		border-bottom-width: 4px;
	}

	.el-menu-item {
		margin: 0 20px;
		padding: 0;
	}

	.el-menu-item a {
		display: block;
	}

	.el-menu--horizontal>.el-menu-item:first-child {
		margin-left: 0;
	}
</style>
