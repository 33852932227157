import {
	getAction,
	putAction,
	postAction,
	httpAction
} from '@/api/manage'

//获取页面管理列表
const getSysUiconfigPage = (params) => getAction("/sys/uiconfig/sysUiconfigPage/list", params)

//通过表名查询字段
const getFieldListByTableNames = (params) => getAction("/fw/fwMainConf/queryFieldListByTableNamesNotAuth", params)

//通过表名查询值		/表名
const getDataListByTableNames = (tableName,params) => getAction("/fw/dynamicForm/getDataListNotAuth/" +tableName, params) 
/* const getDataListByTableNames = (params) => getAction("/fw/dynamicForm/getDataListNotAuth/", params) */

/* getDataList */
//通过表名查询详情  /表名/id
const getDetailById = (params) => getAction("/fw/dynamicForm/queryFormNotAuth"+params)

//通过页面数据id查询数据
const getDetailByIdFromPage = (params) => getAction("/system/uiconfig/sysUiconfigPageData/list", params)

//自定义参数查询详情
const getDetailByCustomNotAuth = (tableName,params) => getAction("/fw/dynamicForm/queryFormCustomNotAuth/"+tableName, params)

//通过id 查询online报表列表字段和数据
const getReportColumnsAndDataById = (id,params) => getAction("/online/cgreport/api/getColumnsAndDataNotAuth/"+id,params)

// 招商添加联系人
const postAddContact = (params) => postAction("/merchants/merchantsConsultInfo/add", params)

//获取数字字典
const getDictItemList = (params) => getAction("/sys/dictItem/list", params)

//查询楼层
const reqGetpageFloor = (params) =>  getAction("/sys/uiconfig/sysUiconfigFloor/list", params)

/* queryForm */
export {
	getSysUiconfigPage,
	getFieldListByTableNames,
	getDataListByTableNames,
	getDetailById,
	getDetailByIdFromPage,
	getDetailByCustomNotAuth,
	getReportColumnsAndDataById,
	postAddContact,
	getDictItemList,
	reqGetpageFloor
}
