<template>
	<div class="content">
		<div class="fLeft">
			<p class="title" v-if="moduleDataSource.title">{{ moduleDataSource.title }}</p>
			<p class="date">
				<span v-if=" moduleDataSource.projectType"
					style="padding:2px 10px;background:#3CA079;color:#fff;border-radius: 8px;">{{ moduleDataSource.projectType }}</span>
				<span v-if=" moduleDataSource.buildNature">项目地址：{{ moduleDataSource.address }}</span>
				<span v-if=" moduleDataSource.buildNature">项目性质：{{ moduleDataSource.buildNature }}</span>
				<span v-if="moduleDataSource.issue_date">{{ moduleDataSource.issue_date }}</span>
			</p>
			<div v-html="removeHtmlStyle(moduleDataSource.content)" class="txt"></div>
			<div class="address" v-show="moduleDataSource.mapPoint">
				<div class="tTitle">
					<span class="line line1"></span>
					<span>项目位置</span>
					<span class="line line2"></span>
				</div>
				<div>
					<div class="map cfff" id="map"></div>
				</div>
			</div>
			<div class="address" v-if="moduleDataSource.voideo">
				<div class="tTitle">
					<span class="line line1"></span>
					<span>项目视频</span>
					<span class="line line2"></span>
				</div>
				<div style="width:60%;margin:0 auto;">
					<vueVideoPlayer :src="moduleDataSource.voideo" />
				</div>
			</div>
		</div>
		<div class="concat-wrap" v-if="moduleDataSource.isShowConcat">
			<i class="el-icon-phone-outline" style="color: #fff;font-size: 48px;"></i>
			<div class="concat">
				<div class="cfff fontSize20" style="line-height: 26px;">{{moduleDataSource.name}}</div>
				<div class="cfff fontSize30" style="line-height: 36px;">{{moduleDataSource.phone}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import vueVideoPlayer from '@/components/base/MyVideoPlayer'
	export default {
		name: 'HuaxianProjectDetail',
		props: {
			moduleDataSource: {
				type: Object,
				default: () => {}
			},
		},
		components: {
			vueVideoPlayer
		},
		data() {
			return {
				showMapInfo: false
			}
		},
		methods: {
			showMap() {

				//res.result.mapPoint,res.result.projectName,res.result.mapAddress
				let infoTitle = this.moduleDataSource.title
				let mapPoint = this.moduleDataSource.mapPoint
				let infoContent = this.moduleDataSource.address
				//mapLongitude  精度     mapLatitude  纬度
				if (mapPoint) {
					this.showMapInfo = true
				} else {
					this.showMapInfo = false
					return
				}
				setTimeout(() => {
					//创建地图
					var map = new window.BMapGL.Map('map')

					//设置中心点坐标
					var point = new window.BMapGL.Point(mapPoint.split(',')[0], mapPoint.split(',')[1])

					//创建遮盖物
					var marker = new window.BMapGL.Marker(point)
					map.addOverlay(marker); // 将标注添加到地图中

					//添加信息窗口
					var opts = {
						title: '项目名称和地址'
					}
					var sContent =
						`<div style="padding:0 10px 20px 10px"><h4>${infoTitle}</h4><p style="font-size:14px">地址:${infoContent}</p></div>`
					var infoWindow = new window.BMapGL.InfoWindow(sContent, opts); //创建信息窗口对象
					map.openInfoWindow(infoWindow, point);
					marker.addEventListener("click", function() {
						map.openInfoWindow(infoWindow, point); //开启信息窗口
					});
					//地图初始化，同时设置地图展示级别
					/* point */
					map.centerAndZoom(point, 15)
					map.setCenter(point)
					//开启鼠标滚轮缩放
					map.enableScrollWheelZoom(true)
					var scaleCtrl = new window.BMapGL.ScaleControl() //添加比例尺控件
					map.addControl(scaleCtrl)
					var zoomCtrl = new window.BMapGL.ZoomControl() //添加缩进控件
					map.addControl(zoomCtrl)
					//var cityCtrl = new window.BMapGL.CityListControl()	//添加城市列表控件
					//map.addControl(cityCtrl)
				}, 1000)
			}
		},
		mounted() {
			this.showMap()
		}
	}
</script>

<style scoped>
	.content {
		overflow: hidden;
	}

	.title {
		font-family: PingFangSC-Medium;
		font-size: 28px;
		color: #181818;
		font-weight: 500;
		margin-bottom: 20px;
		text-align: center;
	}

	.date {
		font-size: 18px;
		color: #999;
		text-align: center;
		margin-bottom: 30px;
	}

	.date span {
		margin: 0 20px;
	}

	.txt {
		font-size: 16px;
		color: #333;
		margin-top: 30px;
	}

	.txt /deep/ p {
		line-height: 2;
		font-size: 16px;
		color: #333;
	}

	.txt /deep/ p span {
		line-height: 2 !important;
		font-size: 16px !important;
		color: #333 !important;
	}

	.txt /deep/ .rich-img {
		max-width: 100%;
		margin: 0 auto;
		height: auto;
	}

	.address {
		margin-top: 20px;
	}

	.addressImg {
		width: 100%;
		margin-bottom: 40px;
	}

	.line1 {
		background: linear-gradient(to left, rgba(60, 160, 121, 1), rgba(60, 160, 121, 0));
	}

	.map {
		width: 60%;
		margin: 0 auto;
		height: 500px;
		text-align: center;
		font-weight: bold;
	}

	.concat {
		width: 204px;
		padding-top: 10px;
		height: 70px;
		padding-left: 10px;
	}

	.concat-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		right: 0;
		top: 400px;
		background: rgba(60, 160, 121, 1);
		border-radius: 12px 0 0 12px;
		width: 285px;
		height: 82px;
	}
</style>
