<template>
	<div class="zsxmTab">
		<div class="container">
			<div class="top">
				<div>
					<img style="vertical-align: baseline;" src="@/assets/images/title_tips.png" alt="">
					<span class="ch">{{floorShowName}}</span>
				</div>
				<div v-if="moduleLinkConfig&&moduleLinkConfig.more&&moduleLinkConfig.more.linkOpenUrl">
					<a href="javascript:void(0);" @click="toList">
						<span class="more">{{moduleLinkConfig.more.buttonName}}</span>
						<i class="el-icon-arrow-right"></i>
					</a>
				</div>
			</div>
			<div class="line">
				<p class="left"></p>
				<p class="right"></p>
		 </div>
		</div>
		<ul class="list">
			<li v-for="item in moduleDataSource" :key="item.id">
				<a href="javascript:void(0)" @click="toDetail(item)">
					<el-image :src="getImgSrc(item.img)" lazy :key="item.id">
						<div slot="error" class="image-slot">
							<i class="el-icon-picture-outline"></i>
						</div>
					</el-image>
					<p class="fontOverHidden">{{ item.title }}</p>
				</a>
			</li>
		</ul>
	</div>
</template>

<script>
	import {
		getFileAccessHttpUrl
	} from '@/utils/util.js'
	export default {
		name: 'HuaxianProjectGrid',
		props: {
			moduleDataSource: {
				type: Array,
				default: () => []
			},
			floorShowName: '',
			moduleTable: '',
			moduleLinkConfig: {}
		},
		methods: {
			getImgSrc(url) {
				return getFileAccessHttpUrl(url)
			},
			toList() {
				this.$emit('navitage', this.moduleLinkConfig.more, this.moduleTable)
			},
			toDetail(item) {
				if(item.page_button_conf && JSON.parse(item.page_button_conf).length>0){
					let button = JSON.parse(item.page_button_conf)[0]
					console.log(button)
					if(button.linkType === 'linkTypeIn'){
						this.$router.push(button.btnOpenPage)
					}
				}else{
					this.$emit('navitage', this.moduleLinkConfig.detail, this.moduleTable, item)
				}
			}
		}
	}
</script>

<style scoped>
	.zsxmTab ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 24px;
	}

	.zsxmTab li {
		width: 282px;
		margin-bottom: 24px;
	}
	.zsxmTab li >>> img{
		transition: transform 0.3s ease; /* 添加过渡动画效果 */
	}
	.zsxmTab li:hover >>> img{
		transform: scale(1.2);
	}
	.zsxmTab li:hover p {
		color: #3CA079;
	}
	.zsxmTab .el-image{
		height:188px;
	}
	.zsxmTab li p {
		margin-top: 16px;
		color: #000000;
	}

	.zsxmTab li:nth-child(n+5) {
		margin-bottom: 0;
	}

	.zsxmTab {
		position: relative;
	}

	/* 标题 start */
	.container {
		width: 100%;
		height: 54px;
	}

	.container .top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 36px;
	}

	.container .top a {
		display: flex;
		align-items: center;
		height: 100%;
	}

	.container .top .ch {
		font-family: PingFangSC-Medium;
		font-size: 28px;
		color: #181818;
		line-height: 36px;
		font-weight: 500;
		margin-left: 10px;
	}

	.container .top .en {
		font-family: PingFangSC-Semibold;
		font-size: 12px;
		color: #3CA079;
		line-height: 16px;
		font-weight: 600;
		margin-left: 10px;
		text-transform: uppercase;
	}

	.container .top .more {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #666666;
	}

	.container .top .el-icon-arrow-right {
		font-size: 16px;
		color: #3CA079;
		margin-left: 4px;
	}

	.line {
		margin-top: 16px;
	}

	.line .left {
		width: 200px;
		height: 2px;
		background-color: #3ca079;
		float: left;
	}

	.line .right {
		width: calc(100% - 226px);
		height: 1px;
		background-color: #cee7dd;
		float: right;
		position: relative;
	}

	.line .right::before {
		width: 20px;
		height: 2px;
		background-color: #3ca079;
		position: absolute;
		content: "";
		left: 0;
		top: 0;
	}

	.line .right::after {
		width: 20px;
		height: 2px;
		background-color: #3ca079;
		position: absolute;
		content: "";
		right: 0;
		top: 0;
	}

	/* 标题 end */
</style>
