<template>
  <div class="xwzxTab">
    <div class="container">
      <div class="top">
        <div>
          <img
            style="vertical-align: baseline"
            src="@/assets/images/title_tips.png"
            alt=""
          />
          <span class="ch">{{ floorShowName }}</span>
        </div>
        <div v-if="moduleLinkConfig">
          <a href="javascript:void(0);" @click="toList">
            <span class="more">{{ moduleLinkConfig.more.buttonName }}</span>
            <i class="el-icon-arrow-right"></i>
          </a>
        </div>
      </div>
      <div class="line">
        <p class="left"></p>
        <p class="right"></p>
      </div>
    </div>
    <div class="content">
      <div class="fLeft">
        <el-carousel height="364px" trigger="click" indicator-position="none">
          <el-carousel-item
            v-if="item.img"
            v-for="(item, index) in moduleDataSource"
            :key="index"
          >
            <a @click="toDetail(item)">
              <el-image :src="item.img"></el-image>
              <p class="txt">{{ item.title }}</p>
            </a>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="fRight">
        <ul>
          <li v-for="(item, index) in moduleDataSource" :key="index">
            <a @click="toDetail(item)" class="fontOverHidden">{{
              item.title
            }}</a>
            <span class="date" style="white-space: nowrap">{{
              item.date
            }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
/* 首页新闻中心 */
export default {
  name: "HuaxianIndexNews",
  props: {
    moduleDataSource: {
      type: Array,
      default: () => [],
    },
    floorShowName: "",
    moduleTable: "",
    moduleLinkConfig: {},
  },
  methods: {
    toList() {
      this.$emit("navitage", this.moduleLinkConfig.more, this.moduleTable);
    },
    toDetail(item) {
      this.$emit(
        "navitage",
        this.moduleLinkConfig.detail,
        this.moduleTable,
        item
      );
    },
  },
};
</script>

<style scoped>
.xwzxTab {
  overflow: hidden;
}

.xwzxTab .content {
  margin-top: 32px;
  min-width: 1200px;
}

.xwzxTab {
}

.xwzxTab .fRight {
  width: 44.166%;
}

.xwzxTab .fRight li {
  height: 52px;
  border-bottom: solid 1px #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  position: relative;
}

.xwzxTab .fRight li::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  background: #cccccc;
  top: 23px;
  left: 0;
}

.xwzxTab .fRight li:hover::before {
  background: #3ca079;
}

.xwzxTab .fRight li .date {
  font-size: 14px;
  color: #999999;
}

.xwzxTab .fRight li:hover a {
  color: #3ca079;
  font-weight: 500;
}

.xwzxTab .fLeft {
  position: relative;
  width: 53.333%;
  height: 364px;
}
.xwzxTab .fLeft .el-carousel--horizontal {
  height: 100%;
}
.xwzxTab .fLeft .el-image {
  width: 100%;
  height: 100%;
}
.xwzxTab .fLeft img {
  width: 100%;
  height: 100%;
}
.xwzxTab .el-carousel__container {
  height: 364px;
}
.xwzxTab .fLeft img {
  display: block;
}
.xwzxTab .txt {
  height: 14.3%;
  background: rgba(0, 0, 0, 0.65);
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  padding-left: 15px;
  align-items: center;
  font-family: PingFangSC-Medium;
  font-size: 20px;
  color: #ffffff;
  font-weight: 500;
  width: 100%;
  box-sizing: border-box;
}
.xwzxTab .txt {
  height: 14.3%;
  background: rgba(0, 0, 0, 0.65);
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  padding-left: 15px;
  align-items: center;
  font-family: PingFangSC-Medium;
  font-size: 20px;
  color: #ffffff;
  font-weight: 500;
  width: 100%;
  box-sizing: border-box;
}

/* 标题 start */
.container {
  width: 100%;
  height: 54px;
}

.container .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
}

.container .top a {
  display: flex;
  align-items: center;
  height: 100%;
}

.container .top .ch {
  font-family: PingFangSC-Medium;
  font-size: 28px;
  color: #181818;
  line-height: 36px;
  font-weight: 500;
  margin-left: 10px;
}

.container .top .en {
  font-family: PingFangSC-Semibold;
  font-size: 12px;
  color: #3ca079;
  line-height: 16px;
  font-weight: 600;
  margin-left: 10px;
  text-transform: uppercase;
}

.container .top .more {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #666666;
}

.container .top .el-icon-arrow-right {
  font-size: 16px;
  color: #3ca079;
  margin-left: 4px;
}

.line {
  margin-top: 16px;
}

.line .left {
  width: 200px;
  height: 2px;
  background-color: #3ca079;
  float: left;
}

.line .right {
  width: calc(100% - 226px);
  height: 1px;
  background-color: #cee7dd;
  float: right;
  position: relative;
}

.line .right::before {
  width: 20px;
  height: 2px;
  background-color: #3ca079;
  position: absolute;
  content: "";
  left: 0;
  top: 0;
}

.line .right::after {
  width: 20px;
  height: 2px;
  background-color: #3ca079;
  position: absolute;
  content: "";
  right: 0;
  top: 0;
}

/* 标题 end */
</style>
