<template>
	<div style="min-height:60vh;">
		<ul>
			<li v-for="item in list" :key="item.id" @click="openinfo(item)">
				<div>
					<div class="fLeft" v-if="item.img">
						<el-image :src="getImgSrc(item.img)"></el-image>
					</div>
					<div class="content">
						<p class="title">{{ item.title }}</p>
						<div class="fontOverHidden3" :class="{txt:true,mt20:!item.isTrue,mt47:item.isTrue}" v-html="item.content">
						</div>
						<p class="mark">
							<span>
								<i class="el-icon-time"></i>
								{{ item.date }}
							</span>
							<span v-if="item.source">
								文章来源：{{ item.source }}
							</span>
						</p>
					</div>
				</div>
			</li>
		</ul>
		<div class="pagination">
			<el-pagination @current-change="handleCurrentChange" layout="prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		getFileAccessHttpUrl
	} from '@/utils/util.js'
	export default {
		name: 'HuaxianNewList',
		props: {
			moduleDataSource: {
				type: Array,
				default: () => []
			},
			modulePagination: {},
			moduleLinkConfig: {},
			moduleTable: '',
			floorId: {
				type: String,
				default: ''
			},
			moduleItem: {
				type: Object
			}
		},
		watch: {
			moduleDataSource: {
				handler(newVal) {
					console.log(newVal)
					this.list = newVal
				},
				deep: true
			}
		},
		data() {
			return {
				page: 1,
				pageSize: this.modulePagination.pageSize,
				total: this.modulePagination.total,
				list: []
			}
		},
		created() {
			this.list = this.moduleDataSource
		},
		methods: {
			handleCurrentChange(val) {
				//发送页码获取数据更新newsList
				console.log(val, this.moduleItem, this.floorId)
				this.$emit('getPageList', val, this.moduleItem, this.floorId)
			},
			getImgSrc(url) {
				return getFileAccessHttpUrl(url)
			},
			openinfo(item) {
				this.$emit('navitage', this.moduleLinkConfig.detail, this.moduleTable, item)
			}
		}
	}
</script>

<style scoped>
	.mt20 {
		margin-bottom: 20px;
	}

	.mt47 {
		margin-bottom: 47px;
	}

	.content {
		padding-top: 5px;
	}

	li {
		padding: 24px 0;
		border-bottom: solid 1px #eee;
		overflow: hidden;
	}

	li:first-child {
		padding-top: 0;
	}

	.el-icon-time {
		font-size: 13px;
		color: #aaa;
	}

	.title {
		font-family: PingFangSC-Medium;
		font-size: 20px;
		color: #000000;
		line-height: 28px;
		font-weight: 500;
	}

	li:hover .title {
		color: #3CA079;
	}

	.txt {
		font-size: 14px;
		color: #666666;
		text-align: justify;
		line-height: 24px;
		margin-top: 12px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		/* 根据业务需求设置第几行显示省略号 */
		overflow: hidden;
	}
	.txt /deep/ img{
		display: none;
	}
	.mark {
		font-size: 12px;
		color: #999999;
		line-height: 20px;
	}

	.mark span {
		margin-right: 16px;
	}

	/deep/ .el-image .el-image__inner {
		width: 320px;
		height: 180px;
	}

	.fLeft {
		margin-right: 24px;
	}

	.pagination {
		text-align: right;
	}

	.pagination .el-pagination {
		margin-bottom: 0;
	}
</style>