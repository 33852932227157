<template>
  <div>
    <div class="container">
        <div class="header"></div>
       <!-- <div class="img">
          <img :src="img" alt="" style="height:100%">
          <div class="txt">{{floorShowName}}</div>
        </div> -->
        <div class="content" @click="detail" v-for="item in moduleDataSource" :key="item.id">
          <div>
            <p v-html="item.title"></p>
            <span>{{handtime(item.time)}}</span>
          </div>
        </div>
        <div class="footer" @click="more"><p>{{moduleLinkConfig.more.buttonName}} >></p></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ZhongDian',
  props: {
    moduleDataSource: {
      type: Array,
      default: () => []
    },
	floorShowName: '',  //楼层名称
	moduleLinkConfig: {},	//按钮对象
	moduleTable:''	//表名
  },
  data () {
    return {}
  },
  methods:{
     handtime (time) {
      if (time && time.length > 8) {
        time = time.slice(0, 10)
      }
      return time
    },
	  more(){
		  //像父组件传递方法    第一个参数 按钮对象     第二个参数 表名
		this.$emit('navitage', this.moduleLinkConfig.more, this.moduleTable)
	  },
	  detail(){
		  //像父组件传递方法    第一个参数 按钮对象   第二个参数 表名   第三 点击的该条对象
		  this.$emit('navitage', this.moduleLinkConfig.detail, this.moduleTable,item)
	  }
  }
}
</script>

<style scoped>
  .container {
    height: 100%;
    width: 394px;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
  }
  .container .header {
    height: 42px;
    width: 100%;
    background-color: rgb(62, 121, 233);
    margin-bottom: 5px;
  }
  .container .img {
    height: 200px;
    width: 100%;
    background-color: rgb(102, 148, 233);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  .container .img .txt {
    position: absolute;
    width: 100%;
    height: 54px;
    background-color: rgba(52, 124, 175, 0.62);
    border: 1px solid rgba(187, 187, 187, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 28px;
    text-align: center;
    line-height: 54px;
  }
  .container .content {
    flex: 1;
  }
  .container .content div {
    margin-top: 5px;
    width: 100%;
    height: 101px;
    background-color: #ffff;
    border-bottom: 1px solid rgba(187, 187, 187, 1);
    font-size: 18px;
  }
  .container .content div p {
    display: inline-block;
    width: 100%;
    height: 61px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 30px;
     cursor:pointer;
    }
  .container .content div span {
    float: right;
    font-size: 18px;
    color: rgba(145, 145, 145, 1);
    margin-top: 14px;
  }
  .container .footer {
    width: 100%;
    height: 42px;
    background-color: rgb(102, 148, 233);
  }
   .container .footer p {
    text-align: center;
    line-height: 42px;
    font-size: 14px;
    color: rgb(255, 255, 255);
   }
</style>
