<template>
	<div class="zxTab">
		<p class="title">联系我们，获取更多招商项目</p>
		<div class="search">
			<el-form ref="form" :rules="rules" :model="formData">
				<el-form-item prop="contact_number">
					<el-input maxlength="11" v-model="formData.contact_number" placeholder="请输入您的手机号码">
						<el-button slot="append" @click="onSubmit">立即咨询</el-button>
					</el-input>
				</el-form-item>
			</el-form>
		</div>
		<p class="en">CONSILL TING</p>
	</div>
</template>

<script>
	import {
		postAddContact
	} from '@/api/api.js'

	export default {
		name: 'HuaxianContact',
		props: {
			moduleDataSource: {
				type: Array,
				default: () => []
			},
			moduleTable: '',
		},
		data() {
			return {
				formData: {
					contact_number: ''
				},
				fieldKey: "",
				rules: {
					contact_number: [{
							required: true,
							message: "请输入联系方式",
						},
						{
							min: 11,
							max: 11,
							message: "请输入11位手机号码",
						},
						{
							pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
							message: "请输入正确的手机号码",
						}
					]
				}
			}
		},
		methods: {
			onSubmit(e) {
				this.$refs.form.validate((valid) => {
					if (valid) {
						let params = {
							contactNumber: this.formData.contact_number,
							sysOrgCode:this.SENDSYSORGCODE
						}
						postAddContact(params).then(res => {
							if (res.success) {
								this.$message({
									message: '请等待项目负责人与您联系!',
									type: 'success'
								});
							}
						})
						//merchants/merchantsConsultInfo/add?showStatus=1
					}
				});
			}
		},
		mounted() {}
	}
</script>

<style scoped>
	.zxTab .en {
		font-family: AlibabaPuHuiTi-Medium;
		font-size: 48px;
		color: #EEEEEE;
		text-align: center;
		line-height: 48px;
		font-weight: 500;
		position: absolute;
		top: 16%;
		width: 100%;
		text-align: center;
		opacity: 0.07;
		z-index: 1;
	}

	.zxTab {
		height: 240px;
		background: url("@/assets/images/hxzs_zx_bg.png") no-repeat;
		background-size: 100% 100%;
		position: relative;
		overflow: hidden;
	}

	.zxTab .title {
		font-family: AlibabaPuHuiTi-Medium;
		font-size: 32px;
		color: #FFFFFF;
		margin-bottom: 32px;
		font-weight: 500;
		text-align: center;
		padding-top: 58px;
	}

	.zxTab .search {
		width: 640px;
		margin: 0 auto;
		position: relative;
		z-index: 2;
	}

	.el-select .el-input {
		width: 520px;
	}

	/deep/ .el-input-group__append {
		background-color: transparent;
		background-image: linear-gradient(270deg, #6FCEB0 0%, #3CA079 100%);
		border-radius: 4px;
		color: #fff;
		border-width: 0;

	}

	/deep/ .el-input__inner {
		line-height: 52px;
		height: 52px;
		font-size: 16px;
		color: #AAAAAA;
	}
</style>
